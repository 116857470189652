import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const apiKey = process.env.REACT_APP_SECURE_KEY;
const Blogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${apiKey}/course/get_blogs`)
      .then(function (response) {
        setData(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className='container'>
        <h2 className='text-center mt-5 mb-4'>Blogs</h2>
        {loading ? (
          <div className='text-center'>Loading...</div>
        ) : (
          <div className='row'>
            {data.map((item, index) => (
              <div className='col-md-4 mb-4' key={index}>
                <div class='card'>
                  <img src={item.blog_image} class='card-img-top' alt='Card Image' />
                  <div class='card-body'>
                    <h5 class='card-title'>{item.blogtitle}</h5>
                    <p class='card-text'>{item.blogdescription}</p>
                    <br />
                    <Link to={`/viewblog/${item._id}`} className='blog_view_btn'>
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Blogs;
