import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from './Components/Header';
import './Style.css';
import Home from './Components/Home/Home';
import Footer from './Components/Footer';
import C from './Course/C';
import Admin from './Components/AdminPannel/Admin';
import LoginAdmin from './Components/Login/LoginAdmin';
import Howto from './Components/Howto';
import TryEditor from './Components/TryEditor';
import ExerciseTest from './Components/ExerciseTest/ExerciseTest';
import Aboutus from './Components/Aboutus';
import Blogs from './Components/Blogs/Blogs';
import ViewBlogs from './Components/Blogs/ViewBlogs';
import Signin from './Components/Sign/Signin';

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />

          <Route path='/C/:id' element={<C />} />
          <Route path='/CPlus/:id' element={<C />} />
          <Route path='/java/:id' element={<C />} />
          <Route path='/computer/:id' element={<C />} />
          <Route path='/javascript/:id' element={<C />} />
          <Route path='/html/:id' element={<C />} />
          <Route path='/css/:id' element={<C />} />
          <Route path='/reactjs/:id' element={<C />} />
          <Route path='/php/:id' element={<C />} />
          <Route path='/bootstrap/:id' element={<C />} />
          <Route path='/msoffice/:id' element={<C />} />
          <Route path='/python/:id' element={<C />} />

          <Route path='/blogs' element={<Blogs />} />
          <Route path='/viewblog/:id' element={<ViewBlogs />} />
          <Route path='/Howto' element={<Howto />} />
          <Route path='/about-us' element={<Aboutus />} />
          <Route path='/login' element={<LoginAdmin />} />
          <Route path='/TryEditor' element={<TryEditor />} />
          <Route path='/admin/admin' element={<Admin />} />
          <Route path='/ExerciseTest' element={<ExerciseTest />} />
          <Route path='/signin' element={<Signin />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
