import React, { useEffect } from 'react'

const ExerciseTest = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
},[]);
  return (
    <>
      Coming soon
    </>
  )
}

export default ExerciseTest
