import React, { useEffect, useState } from "react";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="aboutus-container">
      <div className="tabs">
        <button
          className={`tab ${activeTab === 0 ? "active" : ""}`}
          onClick={() => handleTabClick(0)}
        >
          About us
        </button>
        <button
          className={`tab ${activeTab === 1 ? "active" : ""}`}
          onClick={() => handleTabClick(1)}
        >
          Terms & condition
        </button>
        <button
          className={`tab ${activeTab === 2 ? "active" : ""}`}
          onClick={() => handleTabClick(2)}
        >
          About Privacy
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 0 && (
          <div>
            <h4>
              <b>About Us</b>
            </h4>
            <h5>Codingveda.com</h5>
            <p>
              www.codingveda.com is open source study platform to provide free
              of cost coding knowledge to student, the best online study source
              platform to gain knowledge.
            </p>

            <p>mail: codingveda.com@gmail.com</p>
            <hr />
            <h5>Easy Learning</h5>
            <p>
              codingveda.com focus on simplecity.
              <br />
              codingveda.com design and content easy and way to straight-forward
              learning.
              <br />
              codingveda.com having simple user interface to understand .<br />
              mail: codingveda.com@gmail.com
            </p>
            <hr />
            <h5>Exercise Test for best practice</h5>
            <p>
              www.codingveda.com provide free exercise test to improve your
              knowledge.
              <br />
              mail: codingveda.com@gmail.com
            </p>
            <br />
            <h5>Comment If any Errors</h5>
            <p>
              If you find an error, or broken link, please tell us about it.
              <br />
              If you find any wrong question and answer please comment about
              that
              <br />
              Use the button "chat" at the bottom-right of each page
              <br />
              mail: codingveda.com@gmail.com
            </p>
            <hr />
            <h5>Add Our Link</h5>
            <p>
              If you want others descover codingveda.com, please Add our link.
              <br />
              add link on webpage or social media platform like facebook,
              instagram, youtube.
            </p>
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <h4>
              <b>TERMS & CONDITIONS</b>
            </h4>
            Welcome to our Privacy Policy
            <br />
            Your privacy is critically important to us.
            <br />
            Codingveda is located at:
            <br />
            <br />
            codingveda <br />
            vidhyadhar nagar vidhyadhar nagar, jaipur
            <br />
            302023 - rajasthan , india
            <br />
            <br />
            It is codingveda policy to respect your privacy regarding any
            information we may collect while operating our website. This Privacy
            Policy applies to www.codingveda.com (hereinafter, "us", "we", or
            "www.codingveda.com"). We respect your privacy and are committed to
            protecting personally identifiable information you may provide us
            through the Website. We have adopted this privacy policy ("Privacy
            Policy") to explain what information may be collected on our
            Website, how we use this information, and under what circumstances
            we may disclose the information to third parties. This Privacy
            Policy applies only to information we collect through the Website
            and does not apply to our collection of information from other
            sources.
            <br />
            This Privacy Policy, together with the Terms of service posted on
            our Website, set forth the general rules and policies governing your
            use of our Website. Depending on your activities when visiting our
            Website, you may be required to agree to additional terms of
            service.
            <br />
            <br />
            <h5>Website Visitors</h5>
            <br />
            Like most website operators, codingveda collects
            non-personally-identifying information of the sort that web browsers
            and servers typically make available, such as the browser type,
            language preference, referring site, and the date and time of each
            visitor request.codingveda s purpose in collecting non-personally
            identifying information is to better understand how codingveda "s
            visitors use its website. From time to time, codingveda may release
            non-personally-identifying information in the aggregate, e.g., by
            publishing a report on trends in the usage of its website.
            <br />
            <br />
            codingveda also collects potentially personally-identifying
            information like Internet Protocol (IP) addresses for logged in
            users and for users leaving comments on http://www.codingveda.com
            blog posts. codingveda only discloses logged in user and commenter
            IP addresses under the same circumstances that it uses and discloses
            personally-identifying information as described below.
            <br />
            <br />
            <h5>Gathering of Personally-Identifying Information</h5>
            <br />
            Certain visitors to codingveda websites choose to interact with
            codingveda in ways that require codingveda to gather
            personally-identifying information. The amount and type of
            information that codingveda gathers depends on the nature of the
            interaction. For example, we ask visitors who sign up for a blog at
            http://www.codingveda.com to provide a username and email address.
            <br />
            <br />
            <h5>Security</h5>
            <br />
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
            <br />
            <br />
            <h5>Advertisements</h5>
            <br />
            Ads appearing on our website may be delivered to users by
            advertising partners, who may set cookies. These cookies allow the
            ad server to recognize your computer each time they send you an
            online advertisement to compile information about you or others who
            use your computer. This information allows ad networks to, among
            other things, deliver targeted advertisements that they believe will
            be of most interest to you. This Privacy Policy covers the use of
            cookies by codingveda and does not cover the use of cookies by any
            advertisers.
            <br />
            <br />
            <h5>Links To External Sites</h5>
            <br />
            Our Service may contain links to external sites that are not
            operated by us. If you click on a third party link, you will be
            directed to that third party's site. We strongly advise you to
            review the Privacy Policy and terms of service of every site you
            visit.
            <br />
            We have no control over, and assume no responsibility for the
            content, privacy policies or practices of any third party sites,
            products or services.
            <br />
            <br />
            <h5>codingveda uses Google AdWords for remarketing</h5>
            <br />
            codingveda uses the remarketing services to advertise on third party
            websites (including Google) to previous visitors to our site. It
            could mean that we advertise to previous visitors who haven't
            completed a task on our site, for example using the contact form to
            make an enquiry. This could be in the form of an advertisement on
            the Google search results page, or a site in the Google Display
            Network. Third-party vendors, including Google, use cookies to serve
            ads based on someone's past visits. Of course, any data collected
            will be used in accordance with our own privacy policy and Google's
            privacy policy.
            <br />
            You can set preferences for how Google advertises to you using the
            Google Ad Preferences page, and if you want to you can opt out of
            interest-based advertising entirely by cookie settings or
            permanently using a browser plugin.
            <br />
            <br />
            <h5>Protection of Certain Personally-Identifying Information</h5>
            <br />
            codingveda discloses potentially personally-identifying and
            personally-identifying information only to those of its employees,
            contractors and affiliated organizations that (i) need to know that
            information in order to process it on codingveda behalf or to
            provide services available at codingveda website, and (ii) that have
            agreed not to disclose it to others. Some of those employees,
            contractors and affiliated organizations may be located outside of
            your home country; by using codingveda website, you consent to the
            transfer of such information to them. codingveda will not rent or
            sell potentially personally-identifying and personally-identifying
            information to anyone. Other than to its employees, contractors and
            affiliated organizations, as described above, codingveda discloses
            potentially personally-identifying and personally-identifying
            information only in response to a subpoena, court order or other
            governmental request, or when codingveda believes in good faith that
            disclosure is reasonably necessary to protect the property or rights
            of codingveda , third parties or the public at large.
            <br />
            If you are a registered user of http://www.codingveda.com and have
            supplied your email address, codingveda may occasionally send you an
            email to tell you about new features, solicit your feedback, or just
            keep you up to date with what's going on with codingveda and our
            products. We primarily use our blog to communicate this type of
            information, so we expect to keep this type of email to a minimum.
            If you send us a request (for example via a support email or via one
            of our feedback mechanisms), we reserve the right to publish it in
            order to help us clarify or respond to your request or to help us
            support other users. codingveda takes all measures reasonably
            necessary to protect against the unauthorized access, use,
            alteration or destruction of potentially personally-identifying and
            personally-identifying information.
            <br />
            <br />
            <h5>Aggregated Statistics</h5>
            <br />
            codingveda may collect statistics about the behavior of visitors to
            its website. codingveda may display this information publicly or
            provide it to others. However, codingveda does not disclose your
            personally-identifying information.
            <br />
            <br />
            <h5>Affiliate Disclosure</h5>
            <br />
            This site uses affiliate links and does earn a commission from
            certain links. This does not affect your purchases or the price you
            may pay.
            <br />
            <br />
            <h5>Cookies</h5>
            <br />
            To enrich and perfect your online experience, codingveda uses
            "Cookies", similar technologies and services provided by others to
            display personalized content, appropriate advertising and store your
            preferences on your computer.
            <br />
            A cookie is a string of information that a website stores on a
            visitor's computer, and that the visitor's browser provides to the
            website each time the visitor returns. codingveda uses cookies to
            help codingveda identify and track visitors, their usage of
            http://www.codingveda.com, and their website access preferences.
            codingveda visitors who do not wish to have cookies placed on their
            computers should set their browsers to refuse cookies before using
            codingveda websites, with the drawback that certain features of
            codingveda websites may not function properly without the aid of
            cookies.
            <br />
            By continuing to navigate our website without changing your cookie
            settings, you hereby acknowledge and agree to codingveda use of
            cookies.
            <br />
            <br />
            <h5>E-commerce</h5>
            <br />
            Those who engage in transactions with codingveda – by purchasing
            codingveda services or products, are asked to provide additional
            information, including as necessary the personal and financial
            information required to process those transactions. In each case,
            codingveda collects such information only insofar as is necessary or
            appropriate to fulfill the purpose of the visitor's interaction with
            codingveda . codingveda does not disclose personally-identifying
            information other than as described below. And visitors can always
            refuse to supply personally-identifying information, with the caveat
            that it may prevent them from engaging in certain website-related
            activities.
            <br />
            <br />
            <h5>Privacy Policy Changes</h5>
            <br />
            Although most changes are likely to be minor, codingveda may change
            its Privacy Policy from time to time, and in codingveda sole
            discretion. codingveda encourages visitors to frequently check this
            page for any changes to its Privacy Policy. Your continued use of
            this site after any change in this Privacy Policy will constitute
            your acceptance of such change.
            <br />
            we are kept user information confidential.
          </div>
        )}

        {activeTab === 2 && (
          <div>
            <h4>ABOUT PRIVACY</h4>
            <br />
            Your privacy is critically important to us.
            <br />
            These terms of service outline the rules and regulations for the use
            of codingveda Website. codingveda is located at:
            <br />
            vidhyadhar nagar vidhyadhar nagar, jaipur
            <br />
            302023 - rajasthan , india
            <br />
            By accessing this website we assume you accept these terms of
            service in full. Do not continue to use codingveda website if you do
            not accept all of the terms of service stated on this page.
            <br />
            The following terminology applies to these Terms of Service, Privacy
            Statement and Disclaimer Notice and any or all Agreements: "Client",
            "You" and "Your" refers to you, the person accessing this website
            and accepting the Company's terms of service. "The Company",
            "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
            "Parties", or "Us", refers to both the Client and ourselves, or
            either the Client or ourselves. All terms refer to the offer,
            acceptance and consideration of payment necessary to undertake the
            process of our assistance to the Client in the most appropriate
            manner, whether by formal meetings of a fixed duration, or any other
            means, for the express purpose of meeting the Client's needs in
            respect of provision of the Company's stated services/products, in
            accordance with and subject to, prevailing law of india. Any use of
            the above terminology or other words in the singular, plural,
            capitalisation and/or he/she or they, are taken as interchangeable
            and therefore as referring to same.
            <br />
            <br />
            <h5>Cookies</h5>
            <br />
            We employ the use of cookies. By using codingveda website you
            consent to the use of cookies in accordance with codingveda privacy
            policy.
            <br />
            Most of the modern day interactive web sites use cookies to enable
            us to retrieve user details for each visit. Cookies are used in some
            areas of our site to enable the functionality of this area and ease
            of use for those people visiting. Some of our affiliate /
            advertising partners may also use cookies.
            <br />
            <br />
            <h5>License</h5>
            <br />
            Unless otherwise stated, codingveda and/or it's licensors own the
            intellectual property rights for all material on codingveda . All
            intellectual property rights are reserved. You may view and/or print
            pages from www.codingveda.com for your own personal use subject to
            restrictions set in these terms of service.
            <br />
            You must not:
            <br />
            Republish material from www.codingveda.com
            <br />
            Sell, rent or sub-license material from www.codingveda.com
            <br />
            Reproduce, duplicate or copy material from www.codingveda.com
            <br />
            Redistribute content from codingveda (unless content is specifically
            made for redistribution).
            <br />
            <br />
            <br />
            <h5>User Comments</h5>
            <br />
            This Agreement shall begin on the date hereof.
            <br />
            Certain parts of this website offer the opportunity for users to
            post and exchange opinions, information, material and data
            ('Comments') in areas of the website. codingveda does not screen,
            edit, publish or review Comments prior to their appearance on the
            website and Comments do not reflect the views or opinions of
            codingveda , its agents or affiliates. Comments reflect the view and
            opinion of the person who posts such view or opinion. To the extent
            permitted by applicable laws codingveda shall not be responsible or
            liable for the Comments or for any loss cost, liability, damages or
            expenses caused and or suffered as a result of any use of and/or
            posting of and/or appearance of the Comments on this website.
            <br />
            codingveda reserves the right to monitor all Comments and to remove
            any Comments which it considers in its absolute discretion to be
            inappropriate, offensive or otherwise in breach of these Terms of
            Service.
            <br />
            You warrant and represent that:
            <br />
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so;
            <br />
            The Comments do not infringe any intellectual property right,
            including without limitation copyright, patent or trademark, or
            other proprietary right of any third party;
            <br />
            The Comments do not contain any defamatory, libelous, offensive,
            indecent or otherwise unlawful material or material which is an
            invasion of privacy
            <br />
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
            <br />
            You hereby grant to codingveda a non-exclusive royalty-free license
            to use, reproduce, edit and authorize others to use, reproduce and
            edit any of your Comments in any and all forms, formats or media.
            <br />
            <br />
            <h5>Hyperlinking to our Content</h5>
            <br />
            The following organizations may link to our Web site without prior
            written approval:
            <br />
            Government agencies;
            <br />
            Search engines;
            <br />
            News organizations;
            <br />
            Online directory distributors when they list us in the directory may
            link to our Web site in the same manner as they hyperlink to the Web
            sites of other listed businesses; and Systemwide Accredited
            Businesses except soliciting non-profit organizations, charity
            shopping malls, and charity fundraising groups which may not
            hyperlink to our Web site. These organizations may link to our home
            page, to publications or to other Web site information so long as
            the link: (a) is not in any way misleading; (b) does not falsely
            imply sponsorship, endorsement or approval of the linking party and
            its products or services; and (c) fits within the context of the
            linking party's site. We may consider and approve in our sole
            discretion other link requests from the following types of
            organizations: commonly-known consumer and/or business information
            sources such as Chambers of Commerce, American Automobile
            Association, AARP and Consumers Union; dot.com community sites;
            <br />
            associations or other groups representing charities, including
            charity giving sites,
            <br />
            online directory distributors;
            <br />
            internet portals;
            <br />
            accounting, law and consulting firms whose primary clients are
            businesses; and educational institutions and trade associations.
            <br />
            We will approve link requests from these organizations if we
            determine that: (a) the link would not reflect unfavorably on us or
            our accredited businesses (for example, trade associations or other
            organizations representing inherently suspect types of business,
            such as work-at-home opportunities, shall not be allowed to link);
            (b)the organization does not have an unsatisfactory record with us;
            (c) the benefit to us from the visibility associated with the
            hyperlink outweighs the absence of codingveda ; and (d) where the
            link is in the context of general resource information or is
            otherwise consistent with editorial content in a newsletter or
            similar product furthering the mission of the organization.
            <br />
            These organizations may link to our home page, to publications or to
            other Web site information so long as the link: (a) is not in any
            way misleading; (b) does not falsely imply sponsorship, endorsement
            or approval of the linking party and it products or services; and
            (c) fits within the context of the linking party's site.
            <br />
            If you are among the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must notify us by
            sending an e-mail to vrthalor@gmail.com. Please include your name,
            your organization name, contact information (such as a phone number
            and/or e-mail address) as well as the URL of your site, a list of
            any URLs from which you intend to link to our Web site, and a list
            of the URL(s) on our site to which you would like to link. Allow 2-3
            weeks for a response.
            <br />
            Approved organizations may hyperlink to our Web site as follows:
            <br />
            By use of our corporate name; or
            <br />
            By use of the uniform resource locator (Web address) being linked
            to; or
            <br />
            By use of any other description of our Web site or material being
            linked to that makes sense within the context and format of content
            on the linking party's site.
            <br />
            No use of codingveda logo or other artwork will be allowed for
            linking absent a trademark license agreement.
            <br />
            <br />
            <h5>Iframes</h5>
            <br />
            Without prior approval and express written permission, you may not
            create frames around our Web pages or use other techniques that
            alter in any way the visual presentation or appearance of our Web
            site.
            <br />
            <br />
            <h5>Content Liability</h5>
            <br />
            We shall have no responsibility or liability for any content
            appearing on your Web site. You agree to indemnify and defend us
            against all claims arising out of or based upon your Website. No
            link(s) may appear on any page on your Web site or within any
            context containing content or materials that may be interpreted as
            libelous, obscene or criminal, or which infringes, otherwise
            violates, or advocates the infringement or other violation of, any
            third party rights.
            <br />
            <br />
            <h5>Reservation of Rights</h5>
            <br />
            We reserve the right at any time and in its sole discretion to
            request that you remove all links or any particular link to our Web
            site. You agree to immediately remove all links to our Web site upon
            such request. We also reserve the right to amend these terms of
            service and its linking policy at any time. By continuing to link to
            our Web site, you agree to be bound to and abide by these linking
            terms of service.
            <br />
            <br />
            <h5>Removal of links from our website</h5>
            <br />
            If you find any link on our Web site or any linked web site
            objectionable for any reason, you may contact us about this. We will
            consider requests to remove links but will have no obligation to do
            so or to respond directly to you.
            <br />
            Whilst we endeavour to ensure that the information on this website
            is correct, we do not warrant its completeness or accuracy; nor do
            we commit to ensuring that the website remains available or that the
            material on the website is kept up to date.
            <br />
            <br />
            <h5>Disclaimer</h5>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website (including, without limitation, any
            warranties implied by law in respect of satisfactory quality,
            fitness for purpose and/or the use of reasonable care and skill).
            Nothing in this disclaimer will:
            <br />
            limit or exclude our or your liability for death or personal injury
            resulting from negligence; limit or exclude our or your liability
            for fraud or fraudulent misrepresentation; limit any of our or your
            liabilities in any way that is not permitted under applicable law;
            or exclude any of our or your liabilities that may not be excluded
            under applicable law. The limitations and exclusions of liability
            set out in this Section and elsewhere in this disclaimer: (a) are
            subject to the preceding paragraph; and (b) govern all liabilities
            arising under the disclaimer or in relation to the subject matter of
            this disclaimer, including liabilities arising in contract, in tort
            (including negligence) and for breach of statutory duty.
            <br />
            To the extent that the website and the information and services on
            the website are provided free of charge, we will not be liable for
            any loss or damage of any nature.
            <br />
            <br />
            <h5>Credit & Contact Information</h5>
            <br />
            This Terms of service page was created at privacyterms.io. If you
            have any queries regarding any of our terms, please contact us.
          </div>
        )}
      </div>
    </div>
  );
};

export default Aboutus;
