import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from './Sign/firebase';
// import { auth } from './firebase'; // Import the auth object from firebase

const Header = () => {
  const [toggleicon, setToggleicon] = useState(true);
  const [crossicon, setCrossicon] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const closeMenu = () => {
    setIsNavbarOpen(false);
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      setIsLoggedIn(false);
    });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-light header-bg'>
        <Link className='navbar-brand' to={'/'}>
          <img src='/images/codingveda.png' width='250' alt='LOGO' />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={handleToggle}
        >
          {toggleicon && (
            <img
              src='/images/toggle_icon_sidebar.png'
              className='toggle_icon2'
              width='250'
              alt='toggle_icon'
              onClick={() => {
                setCrossicon(true);
                setToggleicon(false);
              }}
            />
          )}
          {crossicon && (
            <img
              src='/images/Close_icon.png'
              className='toggle_icon3'
              width='250'
              alt='toggle_icon'
              onClick={() => {
                setToggleicon(true);
                setCrossicon(false);
              }}
            />
          )}
        </button>
        <div
          style={{ justifyContent: 'space-between' }}
          className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`}
          id='navbarNav'
        >
          <ul className='navbar-nav'>
            <li className='nav-item active'>
              <Link className='nav-link' to={'/'} onClick={closeMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={'/C/6522f7404e8e5aa51d50935f'} onClick={closeMenu}>
                C
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={'/CPlus/6522f8274e8e5aa51d50936a'} onClick={closeMenu}>
                C++
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={'/java/6532c3574c9e8091963ed075'} onClick={closeMenu}>
                JAVA
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={'/computer/6532c3f14c9e8091963ed077'} onClick={closeMenu}>
                COMPUTER
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={'/javascript/6532c3f64c9e8091963ed078'} onClick={closeMenu}>
                JAVASCRIPT
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/html/6532c3fb4c9e8091963ed079' onClick={closeMenu}>
                HTML
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={'/css/6532c3ff4c9e8091963ed07a'} onClick={closeMenu}>
                CSS
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={'/reactjs/6532c4074c9e8091963ed07c'} onClick={closeMenu}>
                REACT
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to={'/python/665dde769a80c3145bf9d1f5'} onClick={closeMenu}>
                PYTHON
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/Howto' onClick={closeMenu}>
                HOWTO
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/ExerciseTest' onClick={closeMenu}>
                EXERCISE TEST
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/TryEditor' onClick={closeMenu}>
                TRY EDITOR
              </Link>
            </li>
          </ul>
          <div style={{ justifyContent: 'center', display: 'flex', gap: '10px', marginRight:'20px' }}>
            {!isLoggedIn ? (
              <Link to='/signin' className='sign-in' style={{ textDecoration: 'none' }} onClick={closeMenu}>
                Sign In
              </Link>
            ) : (
              <button onClick={handleLogout} className='sign-in' style={{ textDecoration: 'none' }}>
                Log Out
              </button>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
