import React, { useEffect } from 'react';
import Sidebar from './Common/Sidebar';
import Contentarea from './Common/Contentarea';
import { useSelector } from 'react-redux';

const C = (props) => {
  const selectedContent = useSelector((state) => state.data.selectedContent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-2 p-0'>
            <Sidebar />
          </div>
          <div className='col-md-8 contentdiv'>
            <Contentarea selectedContent={selectedContent} />
          </div>
          <div className='col-md-2'></div>
        </div>
      </div>
    </>
  );
};

export default C;
